<template>
  <div class="account-number-key">
    <div class="account-left">
      <div>
        <el-checkbox v-model="checked" @change="advanced">高级检索</el-checkbox>
      </div>
      <div class="account-left-form">
        <el-form ref="submitForm">
          <el-form-item>
            <template slot="label">
              <span>检索词规则</span>

              <el-popover trigger="hover" placement="right">
                <div>
                  <p>检索词配置规则：</p>
                  <p>
                    检索词可配置多组，组之间用空格间隔，组之间关系为或的关系；每组可填写一个词语或者多个词语，多个词语请用&间隔，组内词语间关系为与的关系。
                  </p>
                  <p>
                    例如配置规则1：中国 中国&天安门
                    中国&故宫，该规则会匹配出（包含中国的词语或者包含中国且必须包含天安门的词语或者包含中国且必须包含故宫的词语）
                  </p>
                  <p>
                    例如配置规则2：中国 天安门
                    故宫，该规则会匹配出（包含中国的词语或者包含天安门的词语或者包含故宫的词语）
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-input
              size="small"
              placeholder="请输入检索词，用空格隔开"
              v-model="searchForm.skey"
              type="textarea"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="排除词" v-show="checked">
            <el-input
              size="small"
              v-model="searchForm.excludeKey"
              placeholder="请输入排除词，用空格隔开"
              type="textarea"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item v-show="checked">
            <template slot="label">
              <span>检索群 ID</span>
              <el-popover trigger="hover" placement="right">
                <div>
                  <p>
                    所输入群ID，必须存在于系统内，提交时系统会自动过滤不存在的群ID。
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-input
              size="small"
              type="textarea"
              v-model="searchForm.fromChannelId"
              placeholder="请输入群ID，用空格隔开"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item v-show="checked">
            <template slot="label">
              <span>检索账号ID</span>

              <el-popover trigger="hover" placement="right">
                <div>
                  <p>
                    所输入账号ID，必须存在于系统内，提交时系统会自动过滤不存在的账号ID。
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-input
              size="small"
              type="textarea"
              v-model="searchForm.fromUserId"
              placeholder="请输入账号ID，用空格隔开"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item v-show="checked">
            <template slot="label">
              <span>检索词匹配模式</span>

              <el-popover trigger="hover" placement="right">
                <div>
                  <p>
                    短语匹配：必须出现所输入词语，例如：中国，必须出现‘中国’这个词的消息才会命中。
                  </p>
                  <p>
                    分词匹配：所输入词语可能会命中1个字也会匹配出来，例如：中国，出现‘中’或者‘国’的任一词的消息都会匹配出来。
                  </p>
                  <p>
                    分词匹配(全包含)：同分词匹配，但区别在于，不管词语被分成了几个字，这些字必须全部包含才算命中，例如：中国，出现‘中’且必须出现‘国’的消息才会匹配出来。
                  </p>
                </div>
                <i slot="reference" class="el-icon-question"></i>
              </el-popover>
            </template>
            <el-select
              v-model="searchForm.keyType"
              placeholder="匹配模式"
              size="small"
              style="width:100%"
              clearable
            >
              <el-option label="短语匹配" :value="0"></el-option>
              <el-option label="分词匹配" :value="1"></el-option>
              <el-option label="分词匹配(必须全包含)" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否包含图片" v-show="checked">
            <el-select
              v-model="searchForm.isContainImage"
              placeholder="是否包含图片"
              size="small"
              style="width:100%"
              clearable
            >
              <el-option label="全部" :value="null"></el-option>
              <el-option label="仅图片" :value="1"></el-option>
              <el-option label="不包含图片" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否包含视频" v-show="checked">
            <el-select
              v-model="searchForm.isContainVideo"
              placeholder="是否包含视频"
              size="small"
              style="width:100%"
              clearable
            >
              <el-option label="全部" :value="null"></el-option>
              <el-option label="仅视频" :value="1"></el-option>
              <el-option label="不包含视频" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否检索转发" v-show="checked">
            <el-select
              v-model="searchForm.isForward"
              placeholder="是否检索转发"
              size="small"
              style="width:100%"
              clearable
            >
              <el-option label="全部" :value="null"></el-option>
              <el-option label="仅转发" :value="1"></el-option>
              <el-option label="不包含转发" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始日期" v-show="checked">
            <el-date-picker
              v-model="searchForm.startDate"
              type="datetime"
              size="small"
              style="width:100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="开始日期"
              clearable
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="截至日期" v-show="checked">
            <el-date-picker
              v-model="searchForm.endDate"
              type="datetime"
              size="small"
              style="width:100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="截至日期"
              clearable
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <div
        style="width:100%;padding-right:10px; text-align:center; margin-top: 10px; "
      >
        <el-button
          type="primary"
          size="mini"
          style="width: 100%;  border-radius: 16px"
          @click="searchFun"
          :loading="loading"
        >
          搜 索
        </el-button>
      </div>
      <!-- <logPage title="检索记录" height="calc(100% - 415px)" :logList="[]" /> -->
    </div>
    <div
      class="account-center"
      :style="{ background: list.length == 0 ? 'white' : '' }"
      v-loading="loading"
    >
      <countPage
        padding="5px 10px"
        margin="0 0 10px 0"
        :content="`共计${total}条消息待查阅`"
        style="box-shadow: 0 1px 3px 0 #d8d9d9;"
      />
      <div
        class="right-box"
        id="t-content-scroll"
        v-infinite-scroll="getTelegramUsersSearch"
        :infinite-scroll-disabled="scrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div class="item-box" v-for="(item, i) in list" :key="i">
          <img
            class="left-img"
            :src="
              item.user_photo
                ? 'data:image/png;base64,' + item.user_photo
                : require('@/assets/imgs/p.png')
            "
            alt=""
          />
          <div class="right-content">
            <div class="top">
              <el-popover
                v-if="item.user_id"
                placement="bottom"
                width="400"
                trigger="hover"
              >
                <div class="el-popover-child">
                  <el-descriptions
                    :column="1"
                    :labelStyle="{
                      width: '60px',
                      textAlign: 'right',
                      color: '#060606'
                    }"
                  >
                    <el-descriptions-item label="用户ID">
                      {{ item.user_id }}
                    </el-descriptions-item>
                    <el-descriptions-item label="用户名">
                      {{ item.user_username }}
                    </el-descriptions-item>
                    <el-descriptions-item label="姓">
                      {{ item.user_first_name }}
                    </el-descriptions-item>
                    <el-descriptions-item label="名">
                      {{ item.user_last_name }}
                    </el-descriptions-item>
                    <el-descriptions-item label="账号检索">
                      <span
                        class="account-number-key-retrieval-span"
                        @click="userRetrieval(item.user_id)"
                      >
                        检索
                      </span>
                    </el-descriptions-item>
                    <el-descriptions-item label="手机号">
                      <span
                        style=" padding: 0 10px;height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(2, item.user_id)"
                      >
                        查询
                      </span>
                      <span
                        v-if="userInfo.isYs == 0"
                        style="margin-left: 10px; padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(1, item.user_id)"
                      >
                        查询归属地
                      </span>
                    </el-descriptions-item>
                    <!-- <el-descriptions-item
                      label="归属地"
                      v-if="userInfo.isYs == 0"
                    >
                      <span
                        style=" padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                        @click="userDetail(1, item.user_id)"
                      >
                        查询归属地
                      </span>
                    </el-descriptions-item> -->
                  </el-descriptions>
                  <div class="popover-btn-box">
                    <el-button size="small" @click="remarkFun(item)">
                      标记该消息
                    </el-button>
                    <el-button size="small" @click="remarkAccountFun(item)">
                      标记该账号
                    </el-button>
                    <el-button size="small" @click="shieldAccountFun(item)">
                      屏蔽该账号
                    </el-button>
                    <el-button size="small" @click="localtionGroup(item)">
                      定位到群组
                    </el-button>
                    <el-button size="small" @click="copyLink(item)">
                      复制消息链接
                    </el-button>
                  </div>
                </div>
                <span slot="reference">
                  <span
                    class="top-1"
                    v-if="item.user_first_name || item.user_last_name"
                  >
                    {{ item.user_first_name }}&nbsp; {{ item.user_last_name }}
                  </span>
                  <span v-else class="top-1">
                    {{ item.user_id }}
                  </span>
                </span>
              </el-popover>
              <span
                class="top-2"
                style="margin-right:10px"
                v-if="item.user_first_name || item.user_last_name"
              >
                ID：{{ item.user_id }}
              </span>
              <span class="top-2">{{ item.message_date }}</span>
            </div>
            <div class="content">
              <span v-html="item.message_text"></span>
              <!--转发信息  -->
              <span class="content-qun" v-if="item.message_fwd_from_id !== ''">
                <el-popover
                  placement="bottom"
                  width="300"
                  trigger="hover"
                  v-if="item.forward_user.userId"
                >
                  <div>
                    <el-descriptions
                      :column="1"
                      :labelStyle="{
                        width: '60px',
                        textAlign: 'right',
                        color: '#060606'
                      }"
                    >
                      <el-descriptions-item label="用户ID">
                        {{
                          item.forward_user.userId
                            ? item.forward_user.userId
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="用户名">
                        {{
                          item.forward_user.username
                            ? item.forward_user.username
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="姓">
                        {{
                          item.forward_user.userFirstName
                            ? item.forward_user.userFirstName
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="名">
                        {{
                          item.forward_user.userLastName
                            ? item.forward_user.userLastName
                            : ''
                        }}
                      </el-descriptions-item>
                      <el-descriptions-item label="账号检索">
                        <span
                          class="account-number-key-retrieval-span"
                          @click="userRetrieval(item.user_id)"
                        >
                          检索
                        </span>
                      </el-descriptions-item>
                      <el-descriptions-item label="手机号">
                        <span
                          style=" padding: 0 10px;height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                          @click="userDetail(2, item.forward_user.userId)"
                        >
                          查询
                        </span>
                      </el-descriptions-item>
                      <el-descriptions-item
                        label="归属地"
                        v-if="userInfo.isYs == 0"
                      >
                        <span
                          style=" padding: 0 10px; height: 24px;cursor: pointer;color: #FFFFFF;background: #5b6eb2;border-radius: 5px;text-align: center;line-height: 24px;"
                          @click="userDetail(1, item.forward_user.userId)"
                        >
                          查询归属地
                        </span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <span slot="reference">
                    转自：{{ item.message_fwd_from_id }}
                  </span>
                </el-popover>
                <span v-else>转自：{{ item.message_fwd_from_id }}</span>
              </span>
              <!-- 群信息 -->
              <span class="content-qun" v-if="item.channel_title">
                <el-popover placement="bottom" width="300" trigger="hover">
                  <div>
                    <el-descriptions
                      :column="1"
                      :labelStyle="{
                        width: '60px',
                        textAlign: 'right',
                        color: '#060606'
                      }"
                    >
                      <el-descriptions-item label="群名">
                        {{ item.channel_title }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群号">
                        {{ item.channel_id }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群用户名">
                        {{ item.channel_username }}
                      </el-descriptions-item>

                      <el-descriptions-item label="群简介">
                        {{ item.channel_summary }}
                      </el-descriptions-item>
                      <el-descriptions-item label="群检索">
                        <span
                          class="account-number-key-retrieval-span"
                          @click="retrieval(item)"
                        >
                          检索
                        </span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <span slot="reference">来自群：{{ item.channel_title }}</span>
                </el-popover>
              </span>
            </div>
            <div class="content" v-if="item.message_audio_url !== ''">
              <audio controls>
                <source :src="item.message_audio_url" type="audio/ogg" />
                <source :src="item.message_audio_url" type="audio/mpeg" />
              </audio>
            </div>
            <div class="content" v-if="item.message_img_url !== ''">
              <img :src="item.message_img_url" alt="" :onerror="noPic" />
            </div>
            <div class="content" v-if="item.message_video_url !== ''">
              <video controls :src="item.message_video_url"></video>
            </div>

            <!-- <el-popover
              placement="bottom"
              trigger="hover"
              popper-class="search-key-popover"
            >
              <div class="btn-box">
                <el-button type="text" @click="remarkFun(item)">
                  标记该消息
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="remarkAccountFun(item)"
                >
                  标记该账号
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="shieldAccountFun(item)"
                >
                  屏蔽该账号
                </el-button>
                <el-button
                  style="margin:0"
                  type="text"
                  @click="localtionGroup(item)"
                >
                  定位到群组
                </el-button>
              </div>

              <img
                class="news-more"
                slot="reference"
                :src="require('@/assets/imgs/i5.png')"
                alt=""
              />
            </el-popover> -->
          </div>
        </div>
        <li v-if="dataLoading">
          <span v-if="list.length > 0">加载中...</span>
        </li>
        <li v-else>
          <span v-if="list.length > 0">没有更多了...</span>
        </li>
      </div>
      <noData :text="noDataText" fontSize="18px" v-show="list.length == 0" />
    </div>
    <div class="account-right">
      <div
        id="t-log-box"
        v-infinite-scroll="logLoading"
        :infinite-scroll-disabled="keyWordsScrollDisabled"
        :infinite-scroll-distance="10"
      >
        <div>
          <logPage
            ref="searchIndexLog"
            title="关键词方案"
            :logList="computedKeyWordsList"
            label="groupName"
            @selectLog="keyWordsSelect"
            :showTag="true"
          />
          <li>{{ logText }}</li>
        </div>
      </div>
    </div>
    <userDetail
      ref="userDetailDialog"
      :placeVisible="placeVisible"
      @closePlace="closePlace"
    />
  </div>
</template>

<script>
import { message } from '@/api/retrieval'
import { list as keyWordsApi } from '@/api/monitor'
import { remarkMessage, userBlacklistApi } from '@/api/mark/markAccount'
import { scrollTopFun } from '@/plugins/logFunction'

export default {
  name: 'SearchKey',
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/nopic.png') + '"',
      searchForm: {
        startDate: '',
        endDate: '',
        excludeKey: '',
        fromChannelId: '',
        fromUserId: '',
        isContainImage: '',
        isContainVideo: '',
        isForward: '',
        keyType: '',
        skey: ''
      },
      checked: false, //是否高级检索
      params: {},
      // searchType: 1, //1是搜索。2是关键词跟检索记录
      current: 1,
      limit: 50,
      total: 0,
      list: [],
      listScrollTop: 0,
      dataLoading: true, //是否还有更多数据
      loading: false, //加载状态
      scrollDisabled: true,
      // 关键词数据
      keyWordsScrollDisabled: false,
      keyWordsList: [],
      keyCurrent: 1,
      keyLimit: 50,
      logText: '加载中...',
      logScrollTop: 0,
      // 弹框信息
      placeVisible: false,
      noDataText: '请点击左侧搜索按钮查询数据，或者点击右侧关键词方案查询数据！'
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
    noReadObj() {
      return this.$store.state.windowData.noRead
    },
    computedKeyWordsList() {
      this.keyWordsList.forEach(item => {
        if (item.noRead) {
          delete item.noRead
        }
      })
      let arr = this.keyWordsList.map(item => {
        if (
          this.noReadObj &&
          this.noReadObj.userKeyMap &&
          this.noReadObj.userKeyMap.objMap
        ) {
          for (let i in this.noReadObj.userKeyMap.objMap) {
            if (i == item.id) {
              this.$set(item, 'noRead', this.noReadObj.userKeyMap.objMap[i])
            }
          }
        }
        return item
      })

      return arr
    }
  },
  created() {},
  mounted() {},
  activated() {
    scrollTopFun('t-log-box', 'set', this.logScrollTop)
    scrollTopFun('t-content-scroll', 'set', this.listScrollTop)
  },
  deactivated() {
    this.logScrollTop = scrollTopFun('t-log-box', 'get')
    this.listScrollTop = scrollTopFun('t-content-scroll', 'get')
  },
  methods: {
    // 是否高级检索
    advanced(type) {
      this.searchForm.skey = ''
      this.searchForm.keyType = ''
      this.searchForm.isForward = ''
      this.searchForm.isContainVideo = ''
      this.searchForm.isContainImage = ''
      this.searchForm.fromUserId = ''
      this.searchForm.fromChannelId = ''
      this.searchForm.excludeKey = ''
      this.searchForm.endDate = ''
      this.searchForm.startDate = ''
      this.$refs.searchIndexLog.selectIndex = null
    },
    // 群检索
    retrieval(row) {
      this.$router.push('/group/detail/' + row.channel_id)
    },
    // 定位到群组
    localtionGroup(row) {
      this.$router.push(
        '/group/detail/' + row.channel_id + '?messageId=' + row.message_id
      )
    },
    // 账号检索
    userRetrieval(id) {
      this.$router.push('/user/detail/' + id)
    },
    getTelegramUsersSearch() {
      this.loading = true
      this.scrollDisabled = true
      this.params = Object.assign({}, this.searchForm)
      return new Promise((resolver, reject) => {
        message(this.current, this.limit, this.params).then(data => {
          this.loading = false
          this.total = data.all_count
          if (data.items.length > 0) {
            this.list = this.list.concat(data.items)
            this.current++
            this.scrollDisabled = false
            if (data.items.length < this.limit) {
              this.dataLoading = false
            }
            this.noDataText = ''
          } else {
            this.noDataText = '暂无数据'
            this.dataLoading = false
          }
          resolver()
        })
      })
    },
    searchFun() {
      this.dataLoading = true
      this.scrollDisabled = true
      this.$refs.searchIndexLog.selectIndex = null
      this.current = 1
      this.list = []
      this.getTelegramUsersSearch()
    },
    // 关键词方案数据
    logLoading() {
      this.keyWordsScrollDisabled = true
      keyWordsApi(this.keyCurrent, this.keyLimit).then(data => {
        if (data.items.length > 0) {
          this.logText = '加载中...'
          this.keyWordsScrollDisabled = false
          this.keyCurrent++
          this.keyWordsList = this.keyWordsList.concat(data.items)
          if (data.items.length < this.keyLimit) {
            this.logText = '没有更多了...'
          }
        } else {
          this.logText = '没有更多了...'
        }
      })
    },
    // 标记该消息
    remarkFun(row) {
      remarkMessage({
        uid: row.user_id,
        channelId: row.channel_id,
        messageId: row.message_id,
        remarkType: 1
      }).then(data => {
        this.$message({
          type: 'success',
          message: '消息标记成功！'
        })
      })
    },
    // 标记该账号
    remarkAccountFun(row) {
      remarkMessage({
        uid: row.user_id,
        remarkType: 2
      }).then(data => {
        this.$message({
          type: 'success',
          message: '账号标记成功！'
        })
      })
    },
    // 屏蔽该账号
    shieldAccountFun(row) {
      this.$confirm('确认屏蔽, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          userBlacklistApi({ tgid: row.user_id }).then(() => {
            this.$message({
              type: 'success',
              message: '屏蔽成功！'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消。'
          })
        })
    },
    //
    keyWordsSelect(row) {
      this.searchForm.startDate = row.startDate ? row.startDate : ''
      this.searchForm.endDate = row.endDate ? row.endDate : ''
      this.searchForm.excludeKey = row.excludeKey
      this.searchForm.fromChannelId = row.fromChannelId ? row.fromChannelId : ''
      this.searchForm.fromUserId = row.fromUserId ? row.fromUserId : ''
      this.searchForm.isContainImage = row.isContainImage
        ? row.isContainImage
        : ''
      this.searchForm.isContainVideo = row.isContainVideo
        ? row.isContainVideo
        : ''
      this.searchForm.isForward = row.isForward ? row.isForward : ''
      this.searchForm.keyType = row.keyType
      this.searchForm.skey = row.skey
      this.searchForm.id = row.id
      this.dataLoading = true
      this.scrollDisabled = true
      this.current = 1
      this.list = []
      this.checked = true
      this.getTelegramUsersSearch().then(res => {
        this.scrollDisabled = false
      })
    },
    userDetail(type, uid) {
      let that = this
      this.$confirm(
        `继续查询将消耗${this.userInfo.isYs == 0 ? '积分' : '次数'}，是否继续?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          this.$refs.userDetailDialog.type = type
          this.$refs.userDetailDialog.uid = uid
          this.$refs.userDetailDialog.getBlastUid().then(data => {
            that.placeVisible = true
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 关闭弹框
    closePlace() {
      this.placeVisible = false
    },
    // 复制消息链接
    copyLink(row) {
      let data = row.channel_username
        ? row.channel_username
        : 'c/' + row.channel_id
      let link = `https://t.me/${data}/${row.message_id}`
      const input = document.createElement('input') // 创建一个隐藏input（重要！）
      input.value = link // 赋值
      document.body.appendChild(input)
      input.select() // 选择对象
      if (document.execCommand('copy')) {
        document.execCommand('copy') // 执行浏览器复制命令
        this.$message({ message: '复制成功', type: 'success' })
      }
      document.body.removeChild(input)
    }
  }
}
</script>

<style lang="scss">
.account-number-key {
  width: 100%;
  height: calc(100vh - 79px);
  overflow: hidden;
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  .account-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px;
    padding-right: 0;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .account-left-form {
      max-height: calc(100% - 60px);
      overflow: auto;
      padding-bottom: 10px;
      padding-right: 10px;
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .account-center {
    width: calc(100% - 550px);
    height: 100%;

    // border: 1px solid #e5e5e5;
    border-radius: 5px;
    // padding: 0 10px;
    position: relative;

    // overflow: hidden;
    .right-box {
      width: 100%;
      height: calc(100% - 40px);
      overflow: auto;
      background: #ffffff;
      box-shadow: 0 1px 3px 0 #d8d9d9;
      .item-box {
        width: 100%;
        overflow: hidden;
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #f2f4f8;
        margin-bottom: 10px;
        padding: 13px 10px;

        .left-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .right-content {
          width: calc(100% - 50px);
          margin-left: 10px;
          position: relative;

          padding-right: 10px;
          .top {
            white-space: nowrap;
            .top-1 {
              font-size: 13px;
              margin-right: 15px;
              font-weight: 400;
              color: #4c4c4e;
              line-height: 18px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            .top-2 {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
            }
          }
          .content {
            font-size: 14px;
            margin-top: 5px;
            font-weight: 300;
            color: #030303;
            line-height: 18px;
            .content-qun {
              font-size: 12px;
              color: #666666;
              line-height: 18px;
              margin-top: 5px;
              margin-left: 20px;
              cursor: pointer;
              &:hover {
                color: #0084ff;
              }
            }
            video {
              width: 500px;
              height: 300px;
            }
            img {
              width: 300px !important;
              height: auto !important;
            }
          }
          .news-more {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -10px;
            width: 25px;
            display: none;
            cursor: pointer;
          }
          &:hover {
            .news-more {
              display: block;
            }
          }
        }
      }
    }
    li {
      list-style: none;
      text-align: center;
      color: #555555;
      font-size: 12px;
    }
  }
  .account-right {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    > div {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 10px;
    }
    li {
      list-style: none;
      text-align: center;
      font-size: 12px;
      color: #7e7a7a;
      padding-bottom: 10px;
    }
  }
  ::v-deep .el-popover {
    background: #bb4b4b;
    border: 0px solid #000000;
    box-shadow: 0px 0px 6px 0px #b6b4b4;
    border-radius: 5px;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 5px !important;
  }
}
.account-number-key-retrieval-span {
  text-decoration: underline;
  color: #0079d3;
  user-select: none;
  cursor: pointer;
}
.account-number-key .el-icon-question {
  position: relative;
  color: #7e7a7a;
  margin-left: 5px;
  cursor: pointer;
  z-index: 999;
}
.search-key-popover {
  width: 100px !important;
  min-width: 50px !important;
  .btn-box {
    text-align: center;
  }
}
</style>
