import request from '@/plugins/axios'

// ================telegram接口api==========
export function addKeywordMonitorTask(params) {
  //  添加关键词方案
  return request({
    url: '/userKey',
    method: 'post',
    data: params
  })
}
// 获取列表
export function list(page, limit) {
  return request({
    url: `/userKey/${page}/${limit}`,
    method: 'post',
    data: {}
  })
}

export function delKeyword(id) {
  // 删除
  return request({
    url: `/userKey/${id}`,
    method: 'delete'
  })
}
export function detailKeyword(id) {
  // 本条获取详情
  return request({
    url: `/userKey/${id}`,
    method: 'get'
  })
}
export function update(id, data) {
  // 编辑后提交信息
  return request({
    url: `/userKey/${id}`,
    method: 'put',
    data
  })
}
